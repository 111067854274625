import React from "react";

const Footer = () => {
  return (
    <div className="flex h-20 width-100% bg-white items-center">
      <div className="flex w-[1240px] mx-auto">
        <span className="px-2 font-regular text-inter text-lg text-[#808080] mx-auto">
         © EQT Lab 2023
        </span>
      </div>
    </div>
  );
};

export default Footer;
