import style from "./Hero2.module.scss";
import React from "react";

const Hero2 = () => {
  const newTab = (url) => {
    window.open(url);
  };

  return (
    <div className={style.main}>
      <div>
        <div className="flex flex-col mt-8 xl:pt-24 lg:pt-24 md:pt-8">
          <p className="flex pl-4 pr-4 text-3xl font-bold xl:text-5xl lg:text-5xl md:text-4xl justify-center xl:justify-start lg:justify-start md:justify-start bg-clip-text bg-gradient-to-r from-[#008cff] to-[#00c8ff] text-transparent">
            Your AI finance copilot
          </p>
          <p className={style.parent}>
            EQT offers a fundamentally new AI experience in managing
            personal finances. It effortlessly handles financial accounting and tracking crypto.
          </p>
          <div className="px-4 mt-8 flex space-x-6 xl:mt-16 lg:mt-16 md:mt-16 mx-auto xl:mx-2 lg:mx-2 md:mx-2">
            <div className="my-auto">
              <button
                onClick={() => newTab("https://t.me/eqtlab_bot")}
                className={style.bt}
              >
                Open app
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 flex justify-center xl:justify-end lg:justify-end md:justify-end">
        <div className="my-auto">
          <img
            src="Second_new.png"
            alt="EQT-App"
            className="lg:h-[600px] h-[650px] md:h-[500px]"
          />
          {/* <video
            autoplay="true"
            muted
            loop="true"
            className="h-[650px]"
          >
            <source src="Example.mp4" />
            <source src="example.webm" />
            <source src="example.mov" />
          </video> */}
        </div>
      </div>
    </div>
  );
};

export default Hero2;
