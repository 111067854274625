import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  const newTab = (url) => {
    window.open(url);
  };

  return (
    <div className="flex mx-auto xl:px-20 lg:px-20 md:px-20">
      <div className="flex justify-between items-center h-16 px-4 w-[1120px] mx-auto">
        <div className="my-auto flex justify-between">
          <img src="EQT_logo.svg" alt="logo" className="h-[40px]" />
          <p className="pl-4 text-xl font-Inter font-semibold my-auto text-[#585858]">EQT Lab</p>
        </div>
        <ul className="hidden md:flex">
          <li className="px-4">
            <button
              onClick={() => newTab("EQT-Lab.pdf")}
              className='font-Inter text-base font-normal'
            >
              Pitch deck
            </button>
          </li>
          <li className="px-4">
            <button
              onClick={() => newTab("https://t.me/eqtlab_contact")}
              className='font-Inter text-base font-normal'
            >
              Contact
            </button>
          </li>
        </ul>
        <div onClick={handleNav} className="blok md:hidden">
          {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <div
          className={
            !nav
              ? "fixed right-[20px] shadow-2xl top-[65px] w-[250px] h-[300px] bg-[#f5f5f5] border-r ease-in-out duration-500 rounded-2xl"
              : "fixed right-[-100%]"
          }
        >
          <h1 className="w-full text-xl font-bold m-4 text-[#585858]">Menu</h1>
          <ul className="uppercase p-4">
            <li className="py-2">
              <button
                onClick={() => newTab("EQT-Lab.pdf")}
                className='font-Inter text-lg font-normal text-[#585858]'
              >
                Pitch deck
              </button>
            </li>
            <li className="py-2">
              <button
                onClick={() => newTab("https://t.me/eqtlab_contact")}
                className='font-Inter text-lg font-normal text-[#585858]'
              >
                Contact us
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
