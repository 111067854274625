import "./App.css";
import Footer from "./components/Footer/Footer";
import Hero2 from "./components/hero2/Hero2";
import Navbar from "./components/navbar/Navbar";
import Socials from "./components/Socials/Socials";

function App() {
  return (
    <div>
      <Navbar />
      <Hero2 />
      <Socials />
      <Footer />
    </div>
  );
}

export default App;
