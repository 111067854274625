import React from "react";

const Socials = () => {
  const newTab = (url) => {
    window.open(url);
  };

  return (
    <div className="flex mt-16">
      <div className="flex mx-auto flex-col text-center w-[1000px]">
        <div className="flex px-4 flex-col">
          <p className="flex justify-center lg:pl-5 pt-7 pb-2 font-bold text-3xl lg:text-5xl bg-clip-text bg-gradient-to-r from-[#008cff] to-[#00c8ff] text-transparent">
            Be part of the future
          </p>
          <p className="flex justify-center pt-2 pb-2 lg:pl-5 text-xl font-Inter font-normal text-[gray]">
            Subscribe to our socials and stay in touch.
          </p>
        </div>
        <div className="flex">
          <div className="flex mx-auto space-x-12 mb-4 mt-6">
            <button onClick={() => newTab("https://t.me/eqtlab")} className="">
              <img
                className="flex mx-auto h-[30px]"
                src="TG.svg"
                alt="Telegram"
              />
            </button>
            <button
              onClick={() => newTab("https://t.me/+B7E9jF9HZRM2ZWE6")}
              className=""
            >
              <img
                className="flex mx-auto h-[30px]"
                src="Chat1.svg"
                alt="Chat"
              />
            </button>
            <button
              onClick={() => newTab("https://twitter.com/EQTLab")}
              className=""
            >
              <img className="flex mx-auto h-[30px]" src="X1.svg" alt="X" />
            </button>
            <button
              onClick={() => newTab("https://github.com/eqtlab")}
              className=""
            >
              <img
                className="flex mx-auto h-[30px]"
                src="gh.svg"
                alt="GitHub"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socials;
